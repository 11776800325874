import React, { Suspense, useEffect } from 'react';
import { Canvas } from 'react-three-fiber';
import Shapes, { returnScreenSettings } from './Shapes';
import Lights from './Lights';
import Content from './Content';
import { EffectComposer, ChromaticAberration } from 'react-postprocessing';
import { BlendFunction } from 'postprocessing';
import ReactGA from 'react-ga';
import './App.css'

const postProcessingOffset = returnScreenSettings(0.02, 0.013, 0.007);

export default function App() {
  useEffect(() => {
    ReactGA.initialize('UA-7654525-1');
    ReactGA.pageview(window.location.pathname);
  });

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Content/>
      <Canvas
        pixelRatio={returnScreenSettings(2, 2, 1)}
        gl={{ 
          powerPreference: 'high-performance', 
          alpha: false, 
          stencil: false, 
          depth: false,
          logarithmicDepthBuffer: false,
          premultipliedAlpha: false,
          preserveDrawingBuffer: false,
          physicallyCorrectLights: true
        }}
        pixelRatio={window.devicePixelRatio} 
        camera={{ position: [ 0, 0, 60 ] }}
        >
        <color attach='background' args={['white']} />
        <Suspense fallback={'Loading...'}>
          <Lights />
          <Shapes  />
        </Suspense>
        <EffectComposer>
          <ChromaticAberration 
            offset={[postProcessingOffset, 0]}
            blendFunction={BlendFunction.COLOR_DODGE}
          />
        </EffectComposer>
      </Canvas>
    </div>
  )
}
