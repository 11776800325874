import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Helmet } from 'react-helmet'

const title = 'Front-end development and design in Charleston, WV - Ryan Hagerty';
const description = 'Ryan Hagerty is a senior front-end developer living in Charleston, WV.';
const themecolor = '#242424';

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <title>{ title }</title>
      <meta name="description" content={ description } />
      <meta name="theme-color" content={ themecolor } />
    </Helmet>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
