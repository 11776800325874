import React from 'react';

export default () => {
  return (
    <>
      <ambientLight
        color='purple'
        intensity={2}
      />
      <pointLight
        intensity={1}
        color='slateblue'
        position={[-50, -50, 0]}
        decay={2}
        castShadow={false}
      />
      <pointLight
        intensity={1}
        color='slateblue'
        position={[75, 75, 0]}
        decay={2}
        castShadow={false}
      />
      <pointLight
        intensity={1}
        color='slateblue'
        position={[50, -50, 0]}
        decay={2}
        castShadow={false}
      />
      <directionalLight
        color='magenta'
        position={[2.5, 12, 12]}
        intensity={2}
      />
    </>
  )
}
