import React, { useEffect, useRef } from 'react';
import { MeshDistortMaterial } from '@react-three/drei';
import { a, useSprings } from '@react-spring/three';
import * as easings from 'd3-ease';

const smScreen = window.matchMedia('(max-width: 767px)');
const mdScreen = window.matchMedia('(max-width: 1079px)');
const AnimatedDistortMaterial = a(MeshDistortMaterial);
const blobs = returnScreenSettings(4,5,6);
const blobArray = Array(blobs).fill();
const speed = returnScreenSettings(3, 4, 5);
const distortion = [0.4, 0.5, 0.6, 0.7, 0.8];
const xPosition = returnScreenSettings([-15, -10, -5, 0, 5], [-30, -25, -20, -10, -5], [-15, -10, -5, 0, 5, 10]); 
const yPosition = returnScreenSettings([-10, -5, 0, 5, 10], [-30, -20, -10, -5, 0, 5], [-25, -20, -10, 0, 10, 20, 25]);
const rotation = [2, 2.5, 3, 4, 4.5, 5, 6];
const scale = [[5,5,5], [6,6,6], [7,7,7]];
const colors = ['magenta', '#f72585', '#7209b7', '#3a0ca3', '#4361ee', '#4cc9f0'];

export function returnScreenSettings(sm, md, lg=md) {
  if (smScreen.matches) {
    return sm;
  } else if (mdScreen.matches) {
    return md;
  } else {
    return lg;
  }
}
  
function randomItemFromArray(array) {
  return array[Math.floor(Math.random() * array.length)]
}

const attributes = (i) => {
  const zPos = Math.floor(Math.random() * 15);

  return {
    color: randomItemFromArray(colors),
    distortion: randomItemFromArray(distortion),
    position: [randomItemFromArray(xPosition), randomItemFromArray(yPosition), zPos],
    scale: randomItemFromArray(scale),
    rotation: [randomItemFromArray(rotation), 0, 0],
  }
};

function Blob() {
  const mesh = useRef();

  const [springs, moveSpring] = useSprings(blobs, i => ({
    from: attributes(i),
    to: attributes(i),
    config: {
    mass: 350, 
    tension: 150, 
    friction: 50,
    precision: 0.01,
    easing: easings.easeSinInOut
    }
  }));

  useEffect(() => {
    const interval = setInterval(() => {
    moveSpring((i) => { 
        return ({ ...attributes(i), delay: i * 500 })
    })
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return blobArray.map((blob, index) => (
    <a.mesh 
      key={index}
      {...springs[index]}
    >
    <icosahedronBufferGeometry
      args={[1, 4]}
      ref={mesh}
    />
    <AnimatedDistortMaterial
      speed={speed}
      roughness={1}
      metalness={0}
      color={springs[index].color}
      distort={springs[index].distortion}
    />
    </a.mesh>
  ))
};

function Plane() {
  return (
    <mesh 
      rotation={[ 0, 0, 5 ]}
      position={[ -30, 40, 0 ]}
    >
      <boxBufferGeometry 
        args={[ 250, 35, 1 ]}   
      />
      <meshBasicMaterial
        color='grey'
        roughness={1}
        metalness={0}
      />
    </mesh>
  )
}

export default function Shapes() {
  const position = returnScreenSettings([ -5, 20, 0 ], [ -5, 15, 0 ], [ 30, 0, 0 ]);

  return (
    <>
      <group 
        position={position}
        rotation={[ 0, 0, 35 ]}
      >
        <Blob />
        <Plane />
      </group>
    </>
  )
}
