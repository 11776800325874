import React from 'react';
import ReactTooltip from 'react-tooltip';
import './Content.css'

export default () => {
  return (
    <>
      <main>
        <div className='container'>
          <h1>Ryan Hagerty</h1>
          <h2>Senior Front-end Developer</h2>
          <div className='copy'>I'm probably 
            <span className='emoji tooltip'>
                <span data-tip data-for='mtb' aria-label='mountain biking'>&#128693;</span>
                <ReactTooltip 
                  place='top'
                  type='dark'
                  effect='solid'
                  id='mtb'
                  className='tooltip-component'
                >
                  <span className='tooltip-text'>mountain biking</span>
                </ReactTooltip>
            </span> or eating 
            <span className='emoji tooltip'>
                <span data-tip data-for='pizza' aria-label='pizza'>&#127829;</span>
                <ReactTooltip 
                  place='top'
                  type='dark'
                  effect='solid'
                  id='pizza'
                  className='tooltip-component'
                >
                  <span className='tooltip-text'>pizza</span>
                </ReactTooltip>
            </span> in Charleston, West Virginia.
          </div>
          <div className='contact'> 
              <a href='&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#114;&#121;&#97;&#110;&#64;&#114;&#121;&#97;&#110;&#104;&#97;&#103;&#101;&#114;&#116;&#121;&#46;&#99;&#111;&#109;' className='contact-link email'>
              <svg viewBox='0 0 14 6' width='30px' height='30px'>
                <g>
                  <path d='M7,7L5.268,5.484L0.316,9.729C0.496,9.896,0.739,10,1.007,10h11.986c0.267,0,0.509-0.104,0.688-0.271L8.732,5.484L7,7z'></path>
                  <path d='M13.684,0.271C13.504,0.103,13.262,0,12.993,0H1.007C0.74,0,0.498,0.104,0.318,0.273L7,6L13.684,0.271z'></path>
                  <polygon points='0,0.878 0,9.186 4.833,5.079  '></polygon>
                  <polygon points='9.167,5.079 14,9.186 14,0.875  '></polygon>
                </g>
              </svg>
              <span className='contact-copy'>Email</span>
              </a>
              <a href='https://open.spotify.com/user/ryanhagerty' className='contact-link spotify'>
              <svg xmlns='http://www.w3.org/2000/svg' height='30px' width='30px' version='1.1' viewBox='0 0 168 168'>
                <path d='m83.996 0.277c-46.249 0-83.743 37.493-83.743 83.742 0 46.251 37.494 83.741 83.743 83.741 46.254 0 83.744-37.49 83.744-83.741 0-46.246-37.49-83.738-83.745-83.738l0.001-0.004zm38.404 120.78c-1.5 2.46-4.72 3.24-7.18 1.73-19.662-12.01-44.414-14.73-73.564-8.07-2.809 0.64-5.609-1.12-6.249-3.93-0.643-2.81 1.11-5.61 3.926-6.25 31.9-7.291 59.263-4.15 81.337 9.34 2.46 1.51 3.24 4.72 1.73 7.18zm10.25-22.805c-1.89 3.075-5.91 4.045-8.98 2.155-22.51-13.839-56.823-17.846-83.448-9.764-3.453 1.043-7.1-0.903-8.148-4.35-1.04-3.453 0.907-7.093 4.354-8.143 30.413-9.228 68.222-4.758 94.072 11.127 3.07 1.89 4.04 5.91 2.15 8.976v-0.001zm0.88-23.744c-26.99-16.031-71.52-17.505-97.289-9.684-4.138 1.255-8.514-1.081-9.768-5.219-1.254-4.14 1.08-8.513 5.221-9.771 29.581-8.98 78.756-7.245 109.83 11.202 3.73 2.209 4.95 7.016 2.74 10.733-2.2 3.722-7.02 4.949-10.73 2.739z'></path>
              </svg>
              <span className='contact-copy'>Spotify</span>
              </a>
              <a href='http://www.twitter.com/hotpizzas' className='contact-link twitter'>
                <svg viewBox='0 0 165 110' width='30px' height='30px'>
                  <g transform='translate(-282.32053,-396.30734)'>
                    <path id='path5' d='m453.83 412.81c-6.31 2.8-13.09 4.69-20.21 5.54 7.26-4.35 12.84-11.25 15.47-19.47-6.8 4.03-14.33 6.96-22.34 8.54-6.42-6.84-15.56-11.11-25.68-11.11-19.43 0-35.19 15.75-35.19 35.19 0 2.76 0.31 5.44 0.91 8.02-29.24-1.47-55.17-15.48-72.52-36.76-3.03 5.2-4.76 11.24-4.76 17.69 0 12.21 6.21 22.98 15.65 29.29-5.77-0.18-11.19-1.77-15.94-4.4 0 0.15 0 0.29 0 0.44 0 17.05 12.13 31.27 28.23 34.5-2.95 0.8-6.06 1.23-9.27 1.23-2.27 0-4.47-0.22-6.62-0.63 4.48 13.98 17.47 24.15 32.87 24.43-12.04 9.44-27.21 15.06-43.7 15.06-2.84 0-5.64-0.17-8.39-0.49 15.57 9.98 34.07 15.81 53.94 15.81 64.72 0 100.11-53.62 100.11-100.11 0-1.53-0.03-3.04-0.1-4.55 6.87-4.96 12.84-11.16 17.56-18.21z'></path>
                  </g>
                </svg>
                <span className='contact-copy'>Twitter</span>
              </a>
            </div>
        </div>
      </main>
    </>
  )
}
  